<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Payment Profiles</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Payment-Profiles' }"
              >Payment Profiles</router-link
            >
          </li>
          <li class="breadcrumb-item active">Edit</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Edit the Payment Profile</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form
                id="payment_profile_edit"
                @submit.prevent="submitFormData()"
              >
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label
                      >Service Name <span style="color: red">*</span></label
                    >
                    <select
                      class="form-control"
                      name="service_id"
                      v-model="payment_profile.service_id"
                      required
                    >
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="(service, i) in lists.services"
                        :key="i"
                        :value="i"
                      >
                        {{ service }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Study Year <span style="color: red">*</span></label>
                    <select
                      class="form-control"
                      name="year_id"
                      v-model="payment_profile.year_id"
                      required
                    >
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="year in lists.years"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label
                      >Number Of installments
                      <span style="color: red">*</span></label
                    >
                    <input
                      required
                      type="number"
                      min="1"
                      class="form-control"
                      @keyup="addInstallment()"
                      v-model="payment_profile.num_of_installments"
                      name="num_of_installments"
                      placeholder="Number Of installments"
                    />
                  </div>
                </div>

                <!-- Years: Start -->
                <div
                  v-for="(
                    installments, level
                  ) in payment_profile.grouped_installments"
                  :key="level"
                >
                  <div class="form-group row">
                    <label
                      class="col-md-12 col-form-label"
                      style="color: rgb(120, 27, 32)"
                    >
                      <i class="custom-left-border"></i> {{ level }}
                    </label>
                  </div>

                  <div
                    class="form-group row"
                    v-for="(installment, i) in installments"
                    :key="i"
                  >
                    <input
                      type="hidden"
                      :name="'id_' + installment.level_id + '_' + (i + 1)"
                      v-model="installment.id"
                    />
                    <label class="col-md-2 col-form-label"
                      >Installment {{ i + 1 }}
                      <span style="color: red">*</span></label
                    >
                    <div class="col-md-2">
                      <input
                        type="number"
                        min="1"
                        required
                        v-model="installment.amount"
                        :name="
                          'installment_value_' +
                          installment.level_id +
                          '_' +
                          (i + 1)
                        "
                        class="form-control"
                        placeholder="Amount"
                      />
                    </div>
                    <div class="col-md-4">
                      <input
                        type="date"
                        required
                        v-model="installment.start_date"
                        :name="
                          'start_date_' + installment.level_id + '_' + (i + 1)
                        "
                        class="form-control"
                        placeholder="from"
                      />
                    </div>
                    <div class="col-md-4">
                      <input
                        type="date"
                        required
                        v-model="installment.deadline_date"
                        :name="
                          'deadline_date_' +
                          installment.level_id +
                          '_' +
                          (i + 1)
                        "
                        class="form-control"
                        placeholder="to"
                      />
                    </div>
                  </div>
                </div>
                <!-- Years: End -->

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import usePaymentProfiles from "@/composables/payment_profiles";
import { ref, inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    FeedbackMessages,
  },
  setup(props) {
    var current_num_of_installments = 0;
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const {
      getListsForPaymentProfile,
      getPaymentProfile,
      updatePaymentProfile,
      payment_profile,
      lists,
      msgs,
      errors,
    } = usePaymentProfiles();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("edit_payment_profiles"))
        router.push({
          name: "401",
        });

      getPaymentProfile(props.id).then(() => {
        current_num_of_installments = payment_profile.value.num_of_installments;
      });
      getListsForPaymentProfile();
    });

    const addInstallment = async () => {
      let years = Object.keys(payment_profile.value.grouped_installments);

      if (
        payment_profile.value.num_of_installments > current_num_of_installments
      ) {
        let diff =
          payment_profile.value.num_of_installments -
          current_num_of_installments;
        for (let i = 0; i < diff; i++) {
          years.forEach((year) => {
            payment_profile.value.grouped_installments[year].push({
              id: 0,
              level_id: 0,
              amount: "",
              deadline_date: "",
              start_date: "",
            });
          });
        }
      } else if (
        payment_profile.value.num_of_installments < current_num_of_installments
      ) {
        let diff =
          current_num_of_installments -
          payment_profile.value.num_of_installments;
        for (let i = 0; i < diff; i++) {
          years.forEach((year) => {
            payment_profile.value.grouped_installments[year].pop();
          });
        }
      }

      current_num_of_installments = payment_profile.value.num_of_installments;
    };

    const submitFormData = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#payment_profile_edit");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await updatePaymentProfile(formDataObj, props.id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Payment-Profiles-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      payment_profile,
      addInstallment,
      disableBtn,
      lists,
      msgs,
      errors,
    };
  },
};
</script>
